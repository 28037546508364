import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';


import generic from '../styles/question.module.css';
import useStorage from '../../../hooks/useStorage.ts';

const { getItem, getItems, setItem } = useStorage();

const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { getItem, getItems, setItem } = useStorage();

  /*function check(){
    if(question.required && !answers[question.step]){
      setError('Seleziona una città');
      return false;
    }
    setError(null);
    return true;
  }*/

  useEffect(() => {
    window.dataLayer.push({
      event: 'lead_inviata',
      eventProps: { reqId: getItem('reqId', 'local') },
    });
  }, []);

  return (
    <div className={cn(generic.containerSlide, 'popup-container', 'popup-preventivatore', 'page-form')}>
      <div className={cn(generic.containerSlideLatest, 'popup-content')}>
        <div className={cn(generic.checkingPhoneContainer, generic.errorPhoneContainer, 'error-phone-container')}>
          <div className={cn(generic.greenTitleContainer)}>Consulenza gratuita mutui</div>
          <div className={cn(generic.rowcontainerLead, 'popup-rowcontainer')}>
            <div className={cn(generic.leftColLead, generic.desktop, 'popup-leftCol')}>
              <img
                className={cn(generic.imageCheckDataDesktop)}
                style={{ maxWidth: '100%' }}
                alt="Checking data"
                title="Checking data Tassomutuo"
                src="/super_michael.png"
              />
            </div>
            <div className={cn(generic.errorImageContainer, generic.mobile, 'popup-leftCol')}>
              <img style={{ maxWidth: '100%' }} alt="Checking data" title="Checking data Tassomutuo" src="/super_michael.png" />
            </div>
            <div className={cn(generic.rightColLead, 'popup-rightCol')}>
              <div className={cn(generic.checkingPhoneText)} style={{ fontWeight: 'normal', paddingTop: '30px' }}>
                {question.question_label}
              </div>
              <div className={cn(generic.checkingPhoneText)}>{question.question_subtitle}</div>
              <button
                onClick={() => {
                  setError(null);
                  next(true, question.step);
                }}
                style={{ width: '100%', maxWidth: '450px' }}
                className={cn(generic.nextButton, generic.leadSubmit, generic.greenButton)}
                rel="nofollow">
                {'Mostrami le rate'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className={cn(styles.select)}>
    //   <div className={cn(generic.question_label)}>{question.question_label}</div>
    //   <span className={cn(generic.question_sublabel, generic.requestSentSubLabel)}>{question.question_subtitle}</span>
    //
    //   <div className={cn(generic.stepButton)}>
    //     <button
    //       onClick={() => {
    //         setError(null);
    //         next(true, question.step);
    //       }}
    //       className={cn(generic.nextButton)}>
    //       {'MOSTRAMI LE RATE'}
    //     </button>
    //   </div>
    //
    //   <div className={cn(generic.suggestion)}>{question.suggestion}</div>
    // </div>
  );
};

export default Question;
