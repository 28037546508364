import config from "../../config/config.js";

const { baseUrl } = config;

export default function deleteRequest(id) {
  const endpoint = `${baseUrl}/v1/requests/user/${id}`;
  // // console.log("body to get preventivo", JSON.stringify(BODY));
  const options = {
    method: "DELETE",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `tassomutuo.it`,
      "Access-Control-Allow-Credentials": true,
    },
  };
  // // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response;
    })
    .catch((e) => {
      // throw new Error(e);
    });
}
