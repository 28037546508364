import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './styles/stepper.module.css';

const Stepper = ({ total, current, intro, question }) => {
  const { t } = useTranslation();

  const percentage = question.percentage ? Number(question.percentage) : 0;
  // // console.log(total, current, percentage);
  return (
    <div className={cn(styles.stepper, intro ? styles.intro : '')}>
      <div className={cn(styles.stepperContainer)}>
        <div style={{ width: percentage + '%' }} className={cn(styles.stepperCompleted)}></div>
      </div>
      <div className={cn(styles.percentage)}>{parseInt(percentage) + '%'}</div>
    </div>
  );
};

export default Stepper;
