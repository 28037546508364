import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/select.module.css';
import introstyle from '../styles/intro.module.css';
import generic from '../styles/question.module.css';

import useStorage from '../../../hooks/useStorage.ts';

import getPreventivo from '../../../api/getPreventivo.js';

const Intro = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const { getItem, getItems, setItem } = useStorage();

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  useEffect(() => {
    //GET FISSO E VARIABILE PRIMO RISULTATO
    getPreventivo({
      importo: getItem('step_0', 'local').replaceAll('.', ''),
      valoreImmobile: getItem('step_1', 'local').replaceAll('.', ''),
      tipoTasso: getItem('step_3', 'local'),
      finalita: getItem('step_5', 'local'),
      durata: getItem('step_4', 'local'),
      ordina: 'PerRata',
      eta: getItem('step_2', 'local'),
    }).then(resp => {
      if (resp.preventivi) {
        // // console.log('ashdjhdasjhgdasghj PREVENTIVI', resp.preventivi[Object.keys(resp.preventivi)[0]][0]);
        const better_resp = resp.preventivi[Object.keys(resp.preventivi)[0]][0];
        setItem('product_banca', better_resp.nomebanca, 'local');
        setItem('product_rata', better_resp.rata, 'local');
        setItem('product_tan', better_resp.tan, 'local');
        setItem('product_type', better_resp.tipotasso.toLowerCase(), 'local');
        setItem('validity', true, 'local'); // serve a vedere se è validità open
      }
    });

    getPreventivo({
      importo: getItem('step_0', 'local').replaceAll('.', ''),
      valoreImmobile: getItem('step_1', 'local').replaceAll('.', ''),
      tipoTasso: getItem('step_3', 'local') === 'fisso' ? 'variabile' : 'fisso',
      finalita: getItem('step_5', 'local'),
      durata: getItem('step_4', 'local'),
      ordina: 'PerRata',
      eta: getItem('step_2', 'local'),
    }).then(resp => {
      if (resp.preventivi) {
        // // console.log('ashdjhdasjhgdasghj PREVENTIVI', resp.preventivi[Object.keys(resp.preventivi)[0]][0]);
        const better_resp = resp.preventivi[Object.keys(resp.preventivi)[0]][0];
        setItem('product_other_banca', better_resp.nomebanca, 'local');
        setItem('product_other_rata', better_resp.rata, 'local');
        setItem('product_other_tan', better_resp.tan, 'local');
        setItem('product_other_type', better_resp.tipotasso.toLowerCase(), 'local');
      }
    });
  }, []);

  return (
    <div className={cn(styles.select)}>
      <div className={cn(generic.question_label)}>
        <span>{question.question_label_1}</span>
        <span className={cn(introstyle.valInput)}>{`${formatNumber(getItem('step_0', 'local'))} €`}</span>
        <span>{question.question_label_2}</span>
        <span className={cn(introstyle.valInput)}>tasso {getItem('step_3', 'local')}</span>
      </div>

      <div className={cn(generic.suggestion)}>{question.introtext}</div>

      <div className={cn(generic.stepButton)}>
        {question.step > 0 ? (
          <button
            onClick={() => {
              setError(null);
              prev();
            }}
            className={cn(generic.prevButton)}>
            {'<<'}
          </button>
        ) : null}
        <button
          onClick={() => {
            next(question.last_question, question.step);
          }}
          className={cn(generic.nextButton)}
          rel="nofollow">
          {'AVANTI'}
          <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
        </button>
      </div>

      <div className={cn(generic.suggestion)}>{question.suggestion}</div>
    </div>
  );
};

export default Intro;
