export default function debounce(func, delayMs = 500) {
  let timer;
  return async function anonymousDebounce(...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delayMs);
  };
}
