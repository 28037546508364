import { v4 as uuidv4 } from 'uuid';
import config from '../../config/config.js';

const { baseUrl } = config;

export default async function checkPhone(body) {
  console.log('CHIAMO FIDO', body);
  // avoiding fido error message 'Multiple calls with the same customer id are not supported, please provide a different customer id'
  if (body.customerId) {
    body.customerId = uuidv4();
  }
  const endpoint = `${baseUrl}/v1/requests/check-phone/`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': `tassomutuo.it`,
      'Access-Control-Allow-Credentials': true,
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(endpoint, options);
  const json = await response.json();
  return {response, json};
}
