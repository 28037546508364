export default function getQuestions(questiontype = null) {
  // // console.log("question_type", questiontype);
  const defaultQuestions = [
    {
      step: 0,
      question_label: 'Qual è l’importo del mutuo che vorresti richiedere?',
      question_answers: [],
      question_type: 'price',
      question_placeholder: 'es. 140.000',
      var_name: 'step_0',
      min_value: '30000',
      max_value: '10000000',
      required: 'true',
      show_percentage: true,
      percentage: 10,
      last_question: false,
      suggestion: 'Se non conosci il prezzo preciso inserisci un importo indicativo.',
    },
    {
      step: 1,
      question_label: 'Quanto vuoi spendere per l’acquisto dell’immobile?',
      question_answers: [],
      question_type: 'price',
      min_value: 'prevValue',
      max_value: '10000000',
      var_name: 'step_1',
      required: 'true',
      show_percentage: true,
      percentage: 10,
      question_placeholder: 'es 175.000',
      last_question: false,
      suggestion: 'I tassi più convenienti si trovano su mutui di importo inferiore all’80% rispetto al valore della casa.',
    },
    {
      step: 2,
      question_label: 'Hai meno di 36 anni?',
      question_answers: [
        { value: '34', label: 'SI' },
        { value: '37', label: 'NO' },
      ],
      required: 'true',
      question_type: 'bool',
      var_name: 'step_2',
      show_percentage: true,
      percentage: 20,
      last_question: false,
      suggestion: 'Accedendo al mutuo giovani under 36 potrai ottenere dei tassi agevolati e poter usufruire di imposte inferiori.',
    },
    {
      step: 3,
      question_label: 'Che tipo di Tasso preferisci?',
      question_answers: [
        { value: 'variabile', label: 'Variabile' },
        { value: 'fisso', label: 'Fisso' },
      ],
      required: 'true',
      question_type: 'bool',
      show_percentage: true,
      var_name: 'step_3',
      percentage: 30,
      last_question: false,
      suggestion:
        'Il tasso fisso viene solitamente consigliato. Ti daremo tutte le informazioni necessarie per capire qual’è la scelta migliore per la tua situazione.',
    },
    {
      step: 4,
      question_label: 'Di quale durata vorresti fare il mutuo?',
      question_answers: [
        { value: '5', label: '5' },
        { value: '10', label: '10' },
        { value: '15', label: '15' },
        { value: '20', label: '20' },
        { value: '25', label: '25' },
        { value: '30', label: '30' },
      ],
      required: 'true',
      question_type: 'select',
      var_name: 'step_4',
      last_question: false,
      show_percentage: true,
      percentage: 40,
      suggestion: 'Se hai meno di 45 anni, potrebbe convenirti un mutuo a 30 anni. Se hai più di 45 anni orientati su un mutuo più breve.',
    },
    {
      step: 5,
      question_label: 'Quale finalità avrà il tuo acquisto?',
      question_answers: [
        { value: 'Acquistoprimacasa', label: 'Acquisto prima casa' },
        { value: 'Acquistosecondacasa', label: 'Acquisto seconda casa' },
        { value: 'Acquisto+Ristrutturazione', label: 'Acquisto + Ristrutturazione' },
        { value: 'Acquisto+Sostituzione', label: 'Acquisto + Liquidità' },
        { value: 'Surroga', label: 'Surroga' },
      ],
      required: 'true',
      show_percentage: true,
      var_name: 'step_5',
      percentage: 50,
      question_type: 'buttons',
      last_question: false,
      suggestion:
        'Sapere la finalità del tuo finanziamento è per noi fondamentale, utile a farci capire quale tipo di agevolazione possiamo trovarti.',
    },
    {
      step: 6,
      question_label: 'A che punto sei nella ricerca dell’immobile?',
      question_answers: [
        { value: 'Mi sto guardando intorno', label: 'Mi sto guardando intorno', img: 'guardando_intorno.png' },
        { value: 'Sto per fare una offerta', label: 'Sto per fare una offerta', img: 'fare_offerta.png' },
        { value: 'Ho già fatto una proposta', label: 'Ho già fatto una proposta', img: 'fatto_proposta.png' },
      ],
      required: 'true',
      question_type: 'buttons',
      show_percentage: true,
      percentage: 60,
      var_name: 'step_6',
      last_question: false,
      suggestion:
        'Il Consulente del Credito è la persona che dedicherà il suo tempo a te nella scelta del miglior mutuo e quindi della banca a te più adatta',
    },
    {
      step: 7,
      question_label: 'In quale comune vuoi comprare casa?',
      question_answers: [],
      required: 'true',
      question_type: 'city',
      var_name: 'step_7',
      show_percentage: true,
      percentage: 70,
      last_question: false,
      suggestion: 'Lo sapevi che i nostri consulenti hanno successo in più dell’80% delle pratiche contro una media nazionale di circa il 50%',
    },
    {
      step: 8,
      question_label: 'Ti interessa approfondire la tua idoneità ad accedere al mutuo che desideri?',
      question_answers: [
        { value: 'SI', label: 'SI', next_step: 9 },
        { value: 'NO', label: 'NO', next_step: 'rate' },
      ],
      required: 'true',
      question_type: 'bool',
      var_name: 'step_8',
      show_percentage: true,
      percentage: 80,
      last_question: false,
    },
    {
      step: 9,
      question_label: 'Vuoi ricevere una consulenza gratuita e senza impegno sulla tua pratica di mutuo?',
      question_answers: [
        { value: 'SI', label: 'SI', next_step: 10 },
        { value: 'NO', label: 'NO', next_step: 17 },
      ],
      required: 'true',
      var_name: 'step_9',
      show_percentage: true,
      percentage: 90,
      question_type: 'bool',
      last_question: false,
    },
    {
      step: 10,
      question_label: '',
      // question_subtitle: 'Dove possiamo contattarti?',
      question_subtitle: 'Inserisci i tuoi dati per accedere ad una consulenza gratuita sulla tua situazione.',
      question_answers: [],
      required: 'true',
      // var_name: 'lead',
      question_type: 'lead',
      show_percentage: false,
      percentage: 100,
      generate_lead: true,
      last_question: false,
    },
    {
      step: 11,
      question_label: 'La tua richiesta è stata inviata ai nostri consulenti, sarai ricontattato entro 5 minuti.',
      question_subtitle: 'Nel frattempo, scopri il prospetto di rate che abbiamo preparato per te!',
      question_answers: [],
      show_percentage: false,
      percentage: 100,
      var_name: 'step_16',
      required: 'true',
      question_type: 'contactRequestSent',
      last_question: true,
    },
    {
      step: 17,
      question_label: 'Ti interessa fare un breve test online per conoscere la probabilità di accedere al mutuo che desideri?',
      question_answers: [
        { value: 'SI', label: 'SI', next_step: 18 },
        { value: 'NO', label: 'NO', next_step: 'rate' },
      ],
      required: 'true',
      var_name: 'step_17',
      question_type: 'bool',
      show_percentage: true,
      percentage: 97,
      last_question: false,
    },
    {
      step: 18,
      question_label_1: 'Benvenuto nella sezione di verifica fattibilità per il tuo mutuo da',
      question_label_2: 'a',
      question_label_3: 'con',
      introtext:
        'Ti faremo alcune domande per verificare le tue caratteristiche di solvibilità, così come farebbe una Banca che prende in esame la tua pratica.',
      question_answers: [],
      question_type: 'intro_verify',
      next_step: 19,
      var_name: 'step_18',
      show_percentage: true,
      percentage: 60,
      required: 'false',
      last_question: false,
      suggestion: '',
    },
    {
      step: 19,
      question_label: 'Quanti saranno gli intestatari del mutuo?',
      question_answers: [
        { value: '1', label: '1', next_step: 20 },
        { value: '2', label: '2', next_step: 21 },
        { value: '3', label: '3', next_step: 21 },
        { value: '4', label: '4', next_step: 21 },
      ],
      required: 'true',
      question_type: 'select',
      show_percentage: true,
      percentage: 70,
      last_question: false,
      var_name: 'step_19',
      suggestion: '',
    },
    {
      step: 20,
      question_label: 'Qual’è il tuo reddito mensile netto?',
      question_answers: [],
      next_step: 22,
      question_type: 'price',
      nofear:
        'I dati dei redditi vengono usati esclusivamente per calcolare l’incidenza della rata e valutare la tua probabilità di ricevere il mutuo.',
      required: 'true',
      question_placeholder: 'es 1.750,00',
      show_percentage: true,
      var_name: 'step_20',
      percentage: 80,
      last_question: false,
      suggestion: '',
    },
    {
      step: 21,
      question_label: 'Quale è la somma dei redditi mensili netti degli intestatari?',
      question_answers: [],
      question_type: 'price',
      nofear:
        'I dati dei redditi vengono usati esclusivamente per calcolare l’incidenza della rata e valutare la tua probabilità di ricevere il mutuo.',
      required: 'true',
      show_percentage: true,
      percentage: 80,
      var_name: 'step_21',
      question_placeholder: 'es 2.150,00',
      last_question: false,
      suggestion: '',
    },
    {
      step: 22,
      question_label: 'Qual’è la provenienza di questi redditi?',
      question_answers: [
        { value: 'Contratto Tempo indeterminato', label: 'Contratto Tempo indeterminato' },
        { value: 'Contratto Tempo determinato', label: 'Contratto Tempo determinato' },
        { value: 'Libero professionista', label: 'Libero professionista' },
        { value: 'Pensione', label: 'Pensione' },
      ],
      required: 'true',
      question_type: 'buttons',
      show_percentage: true,
      var_name: 'step_22',
      percentage: 90,
      last_question: false,
      suggestion: '',
    },
    {
      step: 23,
      question_label: '',
      // question_subtitle: 'Dove possiamo contattarti?',
      question_subtitle: 'Personalizza la tua stima!',
      question_answers: [],
      required: 'true',
      next_step: 24,
      show_percentage: false,
      // var_name: 'step_23', qua piglia quello di default
      percentage: 100,
      question_type: 'lead_verify_form',
      generate_lead: true,
      last_question: false,
    },
    {
      step: 24,
      question_label: 'La tua richiesta è stata inviata ai nostri consulenti, sarai ricontattato entro 5 minuti.',
      question_subtitle: 'Nel frattempo, scopri il prospetto di rate che abbiamo preparato per te!',
      question_answers: [],
      show_percentage: false,
      percentage: 100,
      var_name: 'step_24',
      required: 'true',
      question_type: 'lead_verify_result',
      last_question: true,
    },
  ];
  let questions = defaultQuestions;
  if (questiontype) {
    switch (questiontype) {
      case '1':
        questions = [
          {
            step: 0,
            question_label: 'Qual è l’importo del mutuo che vorresti richiedere?',
            question_answers: [],
            question_type: 'price',
            question_placeholder: 'es. 140.000',
            var_name: 'step_0',
            min_value: '30000',
            required: 'true',
            show_percentage: true,
            percentage: 10,
            last_question: false,
            suggestion: 'Se non conosci il prezzo preciso inserisci un importo indicativo.',
          },
          {
            step: 1,
            question_label: 'Quanto vuoi spendere per l’acquisto dell’immobile?',
            question_answers: [],
            question_type: 'price',
            min_value: 'prevValue',
            var_name: 'step_1',
            required: 'true',
            show_percentage: true,
            percentage: 10,
            question_placeholder: 'es 175.000',
            last_question: false,
            suggestion: 'I tassi più convenienti si trovano su mutui di importo inferiore all’80% rispetto al valore della casa.',
          },
          {
            step: 2,
            question_label: 'Hai meno di 36 anni?',
            question_answers: [
              { value: '34', label: 'SI' },
              { value: '37', label: 'NO' },
            ],
            required: 'true',
            question_type: 'bool',
            var_name: 'step_2',
            show_percentage: true,
            percentage: 20,
            last_question: false,
            suggestion: 'Accedendo al mutuo giovani under 36 potrai ottenere dei tassi agevolati e poter usufruire di imposte inferiori.',
          },
          {
            step: 3,
            question_label: 'Che tipo di Tasso preferisci?',
            question_answers: [
              { value: 'variabile', label: 'Variabile' },
              { value: 'fisso', label: 'Fisso' },
            ],
            required: 'true',
            question_type: 'bool',
            show_percentage: true,
            var_name: 'step_3',
            percentage: 30,
            last_question: false,
            suggestion:
              'Il tasso fisso viene solitamente consigliato. Ti daremo tutte le informazioni necessarie per capire qual’è la scelta migliore per la tua situazione.',
          },
          {
            step: 4,
            question_label: 'Di quale durata vorresti fare il mutuo?',
            question_answers: [
              { value: '5', label: '5' },
              { value: '10', label: '10' },
              { value: '15', label: '15' },
              { value: '20', label: '20' },
              { value: '25', label: '25' },
              { value: '30', label: '30' },
            ],
            required: 'true',
            question_type: 'select',
            var_name: 'step_4',
            last_question: false,
            show_percentage: true,
            percentage: 40,
            suggestion: 'Se hai meno di 45 anni, potrebbe convenirti un mutuo a 30 anni. Se hai più di 45 anni orientati su un mutuo più breve.',
          },
          {
            step: 5,
            question_label: 'Quale finalità avrà il tuo acquisto?',
            question_answers: [
              { value: 'Acquisto prima casa', label: 'Acquisto prima casa' },
              { value: 'Acquisto seconda casa', label: 'Acquisto seconda casa' },
              { value: 'Acquisto + Ristrutturazione', label: 'Acquisto + Ristrutturazione' },
              { value: 'Acquisto + Liquidità', label: 'Acquisto + Liquidità' },
            ],
            required: 'true',
            show_percentage: true,
            var_name: 'step_5',
            percentage: 50,
            question_type: 'buttons',
            last_question: false,
            suggestion:
              'Sapere la finalità del tuo finanziamento è per noi fondamentale, utile a farci capire quale tipo di agevolazione possiamo trovarti.',
          },
          {
            step: 6,
            question_label: 'A che punto sei nella ricerca dell’immobile?',
            question_answers: [
              { value: 'Mi sto guardando intorno', label: 'Mi sto guardando intorno', img: 'guardando_intorno.png' },
              { value: 'Sto per fare una offerta', label: 'Sto per fare una offerta', img: 'fare_offerta.png' },
              { value: 'Ho già fatto una proposta', label: 'Ho già fatto una proposta', img: 'fatto_proposta.png' },
            ],
            required: 'true',
            question_type: 'buttons',
            show_percentage: true,
            percentage: 60,
            var_name: 'step_6',
            last_question: false,
            suggestion:
              'Il Consulente del Credito è la persona che dedicherà il suo tempo a te nella scelta del miglior mutuo e quindi della banca a te più adatta',
          },
          {
            step: 7,
            question_label: 'In quale comune vuoi comprare casa?',
            question_answers: [],
            required: 'true',
            question_type: 'city',
            var_name: 'step_7',
            show_percentage: true,
            percentage: 70,
            last_question: false,
            suggestion: 'Lo sapevi che i nostri consulenti hanno successo in più dell’80% delle pratiche contro una media nazionale di circa il 50%',
          },
          {
            step: 8,
            question_label: 'Ti interessa approfondire la tua idoneità ad accedere al mutuo che desideri con una consulenza gratuita?',
            question_answers: [
              { value: 'SI', label: 'SI', next_step: 10 },
              { value: 'NO', label: 'NO', next_step: 'rate' },
            ],
            required: 'true',
            question_type: 'bool',
            var_name: 'step_8',
            show_percentage: true,
            percentage: 80,
            last_question: false,
          },
          {
            step: 10,
            question_label: '',
            // question_subtitle: 'Dove possiamo contattarti?',
            question_subtitle: 'Inserisci i tuoi dati per accedere ad una consulenza gratuita sulla tua situazione.',
            question_answers: [],
            required: 'true',
            // var_name: 'lead',
            question_type: 'lead',
            show_percentage: false,
            percentage: 100,
            generate_lead: true,
            last_question: false,
          },
          {
            step: 11,
            question_label: 'La tua richiesta è stata inviata ai nostri consulenti, sarai ricontattato entro 5 minuti.',
            question_subtitle: 'Nel frattempo, scopri il prospetto di rate che abbiamo preparato per te!',
            question_answers: [],
            show_percentage: false,
            percentage: 100,
            var_name: 'step_16',
            required: 'true',
            question_type: 'contactRequestSent',
            last_question: true,
          },
        ];
        break;
      default:
        questions = defaultQuestions;
    }
  }

  return questions;
}
