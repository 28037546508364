import React, { useState } from 'react';
import styles from './styles/CityAutocompleteInput.module.css';
import axios from 'axios';
import config from '../../config/config';
import cn from 'classnames';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';

const CityAutocompleteInput = ({ label = 'Città', value, className = '', onChange, errorMessage = null, contentContainerStyle = {}, id }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(value);

  const fetchPlace = async text => {
    try {
      const { baseUrl } = config;
      const endpoint = `${baseUrl}/v1/city/autocomplete?city=${text}`;
      const res = await axios.get(endpoint);
      // console.log('REEEEEESP CITIES:', res);
      return res.data.resultsCities;
    } catch (err) {
      return { error: 'Unable to retrieve places' };
    }
  };

  /*  const handleCityChange = async cityToSearch => {
    if (!cityToSearch) return;

    return await fetchPlace(cityToSearch);
  };*/

  const loadOptions = async (inputValue, callback) => {
    const res = await fetchPlace(inputValue);
    callback(res.map(r => ({ label: r.nome, value: r })));
  };

  /*  const loadOptionsDebouce = useMemo(
    () =>
      memoize(
        debounce(async (inputValue, callback) => {
          const res = await fetchPlace(inputValue);
          callback(res.map(r => ({ label: r, value: r })));
        }, 500),
      ),
    [],
  );*/

  //const handleCityInputThrottled = useMemo(async () => _.debounce(loadOptions, 500), []);

  return (
    <div className={cn(styles.inputContainer, errorMessage && styles.error)} style={contentContainerStyle}>
      <label htmlFor={id}>{label}</label>
      <AsyncSelect
        cacheOptions={false}
        defaultOptions={value && [{ value, label: value }]}
        className={cn(styles.select)}
        //loadOptions={_.debounce(loadOptions, 500)}
        loadOptions={loadOptions}
        value={{ value, label: value }}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onChange={onChange}
        styles={{
          control: styles => ({
            ...styles,
            borderRadius: '0px',
            minWidth: '260px',
            borderColor: '#707070',
            minHeight: '55px',
          }),

          input: styles => ({ ...styles, fontSize: '23px', padding: '10px 20px', color: 'rgb(117, 117, 117)' }),
          singleValue: styles => ({ ...styles, fontSize: '23px', padding: '10px 20px', color: 'rgb(117, 117, 117)' }),
          option: styles => ({ ...styles }),
        }}
        noOptionsMessage={() => (inputValue ? t('preventivatore.no_city_message') : t('preventivatore.search_city_message'))}
      />
      {errorMessage ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : (
        <span className={styles.errorMessage} style={{ color: 'transparent' }}>
          '
        </span>
      )}
    </div>
  );
};

export default CityAutocompleteInput;
