import mixpanel from 'mixpanel-browser';

export const track = (eventName, eventProps = null) => {
  window.dataLayer.push({
    event: eventName.replaceAll(' ', '_'),
    eventProps,
  });
  mixpanel.track(eventName, eventProps);

  return null;
};
