import config from "../config/config.js";

const { baseUrl } = config;

export default function getIp() {
  const endpoint = `${baseUrl}/v1/getIp/`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `tassomutuo.it`,
      "Access-Control-Allow-Credentials": true,
    },
  };
  // // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw new Error(JSON.stringify(result));
    });
}
