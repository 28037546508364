import React from 'react';
import cn from 'classnames';
import styles from './styles/CustomTextInput.module.css';

const CustomTextInput = ({
  label,
  type = 'text',
  value,
  onChange,
  errorMessage = null,
  showCurrencyIcon = false,
  showPercentageIcon = false,
  styleInput = {},
  contentContainerStyle = {},
  isNumber = false,
  id,
  ...other
}) => {

  function insertNumber() {

  }


  return (
    <div className={cn(styles.inputContainer, errorMessage && styles.error)} style={contentContainerStyle}>
      <label htmlFor={id}>{label}</label>
      <div className={`${!!showCurrencyIcon || !!showPercentageIcon ? styles.valueInputContainer : ''}`}>
        <input
          inputMode={showCurrencyIcon || showPercentageIcon ? 'decimal' : ''}
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          className={`${!!showCurrencyIcon || !!showPercentageIcon ? 'value_input' : ''}`}
          style={styleInput}
          {...other}
        />
        {showCurrencyIcon && <span style={styleInput} className={styles.valueInputCurrency}>€</span>}
        {showPercentageIcon && <span style={styleInput} className={styles.valueInputCurrency}>%</span>}
      </div>
      {errorMessage ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : (
        <span className={styles.errorMessage} style={{ color: 'transparent' }}>
          '
        </span>
      )}
    </div>
  );
};

export default CustomTextInput;
