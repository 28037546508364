import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CityAutocompleteInput from '../CityAutocompleteInput';
import styles from '../styles/select.module.css';
import generic from '../styles/question.module.css';
import useStorage from '../../../hooks/useStorage.ts';

const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const { getItem, getItems, setItem } = useStorage();

  function check() {
    if (question.required && !answers[question.step]) {
      setError('Seleziona una città');
      return false;
    }
    setError(null);
    return true;
  }

  const options = question.question_answers;
  return (
    <div className={cn(styles.select)}>
      <div className={cn(generic.question_label)}>{question.question_label}</div>

      <CityAutocompleteInput
        label=""
        value={answers[question.step]}
        onChange={e => {
          // // console.log(e.value);
          setItem(`step_${question.step}_regione`, e.value.regione, 'local');
          setItem(`step_${question.step}_provincia`, e.value.provincia, 'local');
          updateAnswer(question.step, e.value.nome, question.var_name);
          setError(null);
          next(question.last_question, question.step, e.value.nome);
        }}
        errorMessage={error}
        options={options}
        ref={null}
      />

      <div className={cn(generic.stepButton)}>
        {question.step > 0 ? (
          <button
            onClick={() => {
              setError(null);
              prev();
            }}
            className={cn(generic.prevButton)}
            rel="nofollow">
            {'<<'}
          </button>
        ) : null}
        <button
          onClick={() => {
            if (check()) {
              setError(null);
              next(question.last_question, question.step);
            }
          }}
          className={cn(generic.nextButton)}
          rel="nofollow">
          {'AVANTI'}
          <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
        </button>
      </div>

      <div className={cn(generic.suggestion)}>{question.suggestion}</div>
    </div>
  );
};

export default Question;
