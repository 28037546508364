import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/select.module.css';
import generic from '../styles/question.module.css';
import CustomTextInput from '../CustomTextInput.js';

const Question = ({ question, next, prev, answers, updateAnswer, nofear = false }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  function formatCurrency(value, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = value;

    // don't validate empty input
    if (input_val === '') {
      return;
    }

    // original length
    var original_len = input_val.length;

    // check for decimal
    if (input_val.indexOf(',') >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      var decimal_pos = input_val.indexOf(',');

      // split number by decimal point
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);

      // On blur make sure 2 numbers after decimal
      if (blur === 'blur') {
        right_side += '00';
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      input_val = left_side + ',' + right_side;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumber(input_val);
      input_val = input_val;

      // final formatting
      if (blur === 'blur') {
        input_val += ',00';
      }
    }

    return input_val;
    // send updated string to input
    // input.val(input_val);
    //
    // // put caret back in the right position
    // var updated_len = input_val.length;
    // caret_pos = updated_len - original_len + caret_pos;
    // input[0].setSelectionRange(caret_pos, caret_pos);
  }

  function validateNumber(s) {
    var rgx = /^[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*\.*[0-9]*$/;
    return s.match(rgx);
  }

  function check() {
    if (question.required && (!answers[question.step] || !Number(answers[question.step].replaceAll('.', '')) > 0)) {
      setError('Inserisci un valore');
      return false;
    }
    if (Number(answers[question.step].replaceAll('.', '')) < Number(question.min_value)) {
      setError('Il valore deve essere superiore a ' + formatNumber(question.min_value) + '€');
      return false;
    }
    if (question.max_value && Number(answers[question.step].replaceAll('.', '')) > Number(question.max_value)) {
      setError('Il valore deve essere inferiore a ' + formatNumber(question.max_value) + '€');
      return false;
    }
    if (
      question.min_value === 'prevValue' &&
      Number(answers[question.step].replaceAll('.', '')) < Number(answers[question.step - 1].replaceAll('.', ''))
    ) {
      setError("Il valore dell'immobile deve essere superiore o almeno uguale a " + formatNumber(answers[question.step - 1]) + '€');
      return false;
    }
    setError(null);
    return true;
  }

  let placeholder = question.question_placeholder;
  if (question.step === 1 && answers[0]) {
    const realValue = answers[0].replaceAll('.', '');
    if (Number(realValue)) {
      placeholder = `es ${formatNumber((Number(realValue) * 1.2).toFixed(0))}`;
    }
  }

  return (
    <div className={cn(styles.select)}>
      <div className={cn(generic.question_label)}>
        {question.question_label}
        {question.nofear ? (
          <div className="nofear">
            <span style={{ color: 'rgb(46 153 67)', fontWeight: 'bold' }}>Niente paura!</span> {question.nofear}
          </div>
        ) : null}
      </div>

      <CustomTextInput
        label={''}
        type="text"
        showCurrencyIcon
        contentContainerStyle={question.nofear ? { minHeight: '180px' } : {}}
        placeholder={placeholder}
        step="1000"
        value={answers[question.step] ? formatNumber(answers[question.step]) : ''}
        className="compact_custom_input"
        onChange={e => {
          if (validateNumber(e.target.value)) {
            updateAnswer(question.step, e.target.value, question.var_name);
          }
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            // TODO fai qualcosa
            if (check()) {
              next(question.last_question, question.step);
            }
          }
        }}
        isNumber={true}
        errorMessage={error}
        ref={null}
      />
      <div className={cn(generic.stepButton)}>
        {question.step > 0 ? (
          <button
            onClick={() => {
              setError(null);
              prev();
            }}
            className={cn(generic.prevButton)}
            rel="nofollow">
            {'<<'}
          </button>
        ) : null}
        <button
          onClick={() => {
            if (check()) {
              next(question.last_question, question.step);
            }
          }}
          className={cn(generic.nextButton)}
          rel="nofollow">
          {'AVANTI'}
          <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
        </button>
      </div>

      <div className={cn(generic.suggestion)}>{question.suggestion}</div>
    </div>
  );
};

export default Question;
