import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Select from './questionsType/select.js';
import Buttons from './questionsType/buttons.js';
import Price from './questionsType/price.js';
import Intro from './questionsType/intro.js';
import Bool from './questionsType/bool.js';
import City from './questionsType/city.js';
import Lead from './questionsType/lead.js';
import LeadVerify from './questionsType/lead_verify.js';
import LeadVerifyForm from './questionsType/lead_verify_form.js';
import LeadVerifyResult from './questionsType/lead_verify_result.js';
import IntroVerify from './questionsType/intro_verify.js';
import ContactRequestSent from './questionsType/ContactRequestSent.js';
import styles from './styles/question.module.css';

const Question = ({ question, next, prev, answers, updateAnswer, requestBody = null }) => {
  const { t } = useTranslation();
  const quest = [];
  switch (question.question_type) {
    case 'select':
      quest.push(
        <Select
          key={`question_select_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
        />,
      );
      break;
    case 'intro':
      quest.push(
        <Intro
          key={`question_intro_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
        />,
      );
      break;
    case 'buttons':
      quest.push(
        <Buttons
          key={`question_buttons_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
        />,
      );
      break;
    case 'price':
      quest.push(
        <Price
          key={`question_price_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
        />,
      );
      break;
    case 'bool':
      quest.push(
        <Bool
          key={`question_bool_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
        />,
      );
      break;
    case 'city':
      quest.push(
        <City
          key={`question_city_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
        />,
      );
      break;
    case 'lead':
      quest.push(
        <Lead
          key={`question_lead_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
          requestBody={requestBody}
        />,
      );
      break;
    case 'lead_verify':
      quest.push(
        <LeadVerify
          key={`question_lead_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
          requestBody={requestBody}
        />,
      );
      break;
    case 'lead_verify_form':
      quest.push(
        <LeadVerifyForm
          key={`question_lead_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
          requestBody={requestBody}
        />,
      );
      break;
    case 'lead_verify_result':
      quest.push(
        <LeadVerifyResult
          key={`question_lead_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
          requestBody={requestBody}
        />,
      );
      break;
    case 'intro_verify':
      quest.push(
        <IntroVerify
          key={`question_lead_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
          requestBody={requestBody}
        />,
      );
      break;
    case 'contactRequestSent':
      quest.push(
        <ContactRequestSent
          key={`question_contactok_step_${question.step}`}
          question={question}
          next={next}
          prev={prev}
          answers={answers}
          updateAnswer={updateAnswer}
        />,
      );
      break;
    default:
      quest.push(<div>ssss</div>);
  }

  // // console.log(question);
  return (
    <div key={`question_container_step_${question.step}`} className={cn(styles.question)}>
      <div className={cn(styles.label)}>{quest}</div>
    </div>
  );
};

export default Question;
