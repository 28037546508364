import config from '../../config/config.js';
import debounce from '../../utilities/debounce.js';

const { baseUrl } = config;

async function updateAnswers(BODY) {
  const endpoint = `${baseUrl}/v1/requests/update-answers/`;
  // // console.log("body to get preventivo", JSON.stringify(BODY));
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': `tassomutuo.it`,
      'Access-Control-Allow-Credentials': true,
    },
    body: JSON.stringify(BODY),
  };
  // // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then(response => {
      // // console.log('login response', response);
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch(e => {
      // throw new Error(JSON.stringify(e));
      // console.log(JSON.stringify(e));
    });
}

const debounced = debounce(updateAnswers, 500);

export {updateAnswers, debounced as updateAnswersDebounced};
