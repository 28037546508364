import config from '../config/config.js';

const { baseUrl } = config;

export default function getPreventivo({
  nomebanca = 'Tutte',
  importo = '',
  valoreImmobile = '',
  tipoTasso = '',
  finalita = '',
  durata = '',
  ordina = '',
  eta = '',
}) {
  const endpoint = `${baseUrl}/v1/preventivo/`;

  const bodyToSend = { nomebanca, importo, valoreImmobile, tipoTasso, finalita, durata, ordina, eta };
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': `tassomutuo.it`,
      'Access-Control-Allow-Credentials': true,
    },
    body: JSON.stringify(bodyToSend),
  };
  return fetch(endpoint, options)
    .then(response => {
      return response.json();
    })
    .catch(e => {
      // throw new Error(JSON.stringify(result));
    });
}
