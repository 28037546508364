import React, { useEffect,  useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import mixpanel from 'mixpanel-browser';


// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


import styles from '../styles/lead.module.css';
import generic from '../styles/question.module.css';
import CustomTextInput from '../CustomTextInput';
import CustomTelephoneInput from '../CustomTelephoneInput';


import useStorage from '../../../hooks/useStorage.ts';
import checkPhone from '../../../api/request/checkPhone.js';
import { track } from '../../../utilities/trackerStatistics.js';

const Question = ({ question, next, prev, answers, updateAnswer, requestBody }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('');
  const [numberOfCheck, setNumberOfCheck] = useState(0);
  const router = useRouter();

  const { getItem, getItems, setItem } = useStorage();
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const validateEmail = email => {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) !== null
    );
  };

  const validatePhone = phone => {
    return typeof phone === 'string' && phone.length >= 5 && phone.length < 16 && phone.match(/^\+?[0-9]+$/);
    // if (!phone) {
    //   return false;
    // }
    // const validNumber = isValidPhoneNumber(phone, 'IT');
    // if (!validNumber || phone.length > 11 || phone.length < 9) {
    //   return false;
    // }
    // return String(phone).match(/^[0-9]+$/);
    // return !!phone;
  };

  function check() {
    if (!answers[question.step]) {
      setError('Inserisci il tuo nome e cognome');
      return false;
    }
    if (!validatePhone(answers[question.step + 3])) {
      setError('Inserisci un numero di telefono valido');
      return false;
    }

    if (!answers[question.step + 6]) {
      setError('Accetta privacy policy e termini e condizioni per continuare');
      return false;
    }
    setError(null);
    return true;
  }

  function phoneControl() {
    const action = 'check_phone_cerca_mutuo';
    let phoneToCheck = '';
    if (answers[question.step + 4]) {
      phoneToCheck += answers[question.step + 4];
    } else {
      phoneToCheck += '+39';
    }
    if (answers[question.step + 3]) {
      phoneToCheck += answers[question.step + 3];
    }
    // console.log('il numero di telefono da controllare', phoneToCheck);
    setStatus('requesting');
    let customerId = getItem('fidoCustomerId', 'local');
    if (!customerId) {
      customerId = uuidv4();
      setItem('fidoCustomerId', customerId, 'local');
    }

    setNumberOfCheck(numberOfCheck + 1);
    // console.log('FIDO - check:', numberOfCheck);

    if (numberOfCheck >= 2) {
      // console.log('FIDO - Too many attempts!!!');
      router.push(`/preventivi-mutuo/?show_chat=1`);
    }
     checkPhone({ phone: phoneToCheck, customerId, action })
       .then(({ response, json }) => {
         // console.log();
         if (response.status === 401) {
           // console.log('recaptcha has blocked the request');
           setStatus('phoneError');
         } else if (response.status >= 402 && response.status <= 405) {
           // console.log('fido has invalidated the phone number');
           setStatus('phoneError');
         } else if (response.status === 200) {
           next(question.last_question);
         } else {
           // console.log('fido request has failed');
           setStatus('phoneError');
         }
         if (json && json.responseTime) {
           const mixpanelInfo = {
             response_time_ms: Math.floor(json.responseTime).toString(),
             message: json.message,
             date: moment().format('DD/MM/YYYY HH:mm:ss'),
             phone: phoneToCheck,
             score: json.score.toString(),
             score_cluster: json.score_cluster,
           };
          //  mixpanel.track('fido check phone', mixpanelInfo);
           track('fido check phone', mixpanelInfo);
         }
       })
       .catch(errPhoneCheck => {
         // console.log('error while checking phone', errPhoneCheck);
         setStatus('phoneError');
       });

    // if (executeRecaptcha) {
    //   executeRecaptcha(action)
    //     .then(token => {
    //       if (numberOfCheck >= 2) {
    //         // console.log('FIDO - Too many attempts!!!');
    //         router.push(`/preventivi-mutuo/?show_chat=1`);
    //       }
    //       checkPhone({ phone: phoneToCheck, token, customerId, action })
    //         .then(({ response, json }) => {
    //           // console.log();
    //           if (response.status === 401) {
    //             // console.log('recaptcha has blocked the request');
    //             setStatus('phoneError');
    //           } else if (response.status >= 402 && response.status <= 405) {
    //             // console.log('fido has invalidated the phone number');
    //             setStatus('phoneError');
    //           } else if (response.status === 200) {
    //             next(question.last_question);
    //           } else {
    //             // console.log('fido request has failed');
    //             setStatus('phoneError');
    //           }
    //           if (json && json.responseTime) {
    //             const mixpanelInfo = {
    //               response_time_ms: Math.floor(json.responseTime).toString(),
    //               message: json.message,
    //               date: moment().format('DD/MM/YYYY HH:mm:ss'),
    //               phone: phoneToCheck,
    //               score: json.score.toString(),
    //               score_cluster: json.score_cluster,
    //             };
    //             mixpanel.track('fido check phone', mixpanelInfo);
    //           }
    //         })
    //         .catch(errPhoneCheck => {
    //           // console.log('error while checking phone', errPhoneCheck);
    //           setStatus('phoneError');
    //         });
    //     })
    //     .catch(err => {
    //       // console.log('error while executing recaptcha', err);
    //       setStatus('phoneError');
    //     });
    // } else {
    //   // console.log('executeRecaptcha not yet available');
    //   setStatus('phoneError');
    // }
  }

  const overrideMinHeight = { minHeight: '10px' };

  useEffect(() => {
    // skip la prossima domandda se è già stata fatta una lead
    if (getItem('reqId', 'local')) {
      next();
    }
  });

  useEffect(() => {
    window.dataLayer.push({
      event: 'consulenza',
    });
  }, []);

  function requestingStatus() {
    return (
      <div className={cn(generic.checkingPhoneContainer)}>
        <div className={cn(generic.greenTitleContainer)}>Consulenza gratuita mutui</div>
        <div className={cn(generic.checkingPhoneText)}>
          Stiamo <span className={cn(generic.checkingPhoneBlue)}>verificando</span> i dati che hai inserito in modo da offrirti la{' '}
          <span className={cn(generic.checkingPhoneBlue)}>migliore consulenza</span> possibile, un attimo di pazienza...
        </div>
        <div className={cn(generic.checkingPhoneGifContainer)}>
          <img alt="Checking data" style={{ maxWidth: '100%', width: '400px' }} title="Checking data Tassomutuo" src="/check-phone.gif" />
        </div>
      </div>
    );
  }

  function phoneErrorStatus() {
    return (
      <div className={cn(generic.checkingPhoneContainer, generic.errorPhoneContainer, 'error-phone-container')}>
        <div className={cn(generic.greenTitleContainer)}>Consulenza gratuita mutui</div>
        <div className={cn(generic.rowcontainerLead, 'popup-rowcontainer')}>
          <div className={cn(generic.leftColLead, generic.desktop, 'popup-leftCol')}>
            <img alt="Checking data" style={{ maxWidth: '100%' }} title="Checking data Tassomutuo" src="/robot.png" />
          </div>
          <div className={cn(generic.errorImageContainer, generic.mobile, 'popup-leftCol')}>
            <img alt="Checking data" style={{ maxWidth: '100%' }} title="Checking data Tassomutuo" src="/robot.png" />
          </div>
          <div className={cn(generic.rightColLead, 'popup-rightCol')}>
            <div className={cn(generic.checkingPhoneText)} style={{ fontWeight: 'normal', maxWidth: '100%' }}>
              Dai nostri controlli è emerso che il numero di telefono inserito non è corretto.
            </div>
            <div className={cn(generic.checkingPhoneText)} style={{ maxWidth: '100%' }}>
              Per poterti erogare la consulenza richiesta, ti chiediamo di inserire un numero valido
            </div>
            <CustomTelephoneInput
              label={'Telefono'}
              type="text"
              value={answers[question.step + 3] ? answers[question.step + 3] : ''}
              onChange={e => {
                updateAnswer(question.step + 3, (e?.target?.value || '').replace(/\D+/g, ''), question.var_name);
              }}
              initialValuePrefix="+39"
              onChangePrefix={val => updateAnswer(question.step + 4, val, question.var_name)}
              // errorMessage={errors?.phone?.message}
              ref={null}
            />
            <button
              onClick={() => {
                if (check()) {
                  // TODO mi metto qui in mezzo per fare il controllo del telefono
                  phoneControl();
                  // next(question.last_question);
                  window.scrollTo(0, 0);
                }
              }}
              style={{ width: '100%' }}
              className={cn(generic.nextButton, generic.lastButton, generic.leadSubmit, generic.greenButton)}>
              {'Prosegui >>'}
            </button>
            {/* <div style={{ textAlign: 'center', marginBottom: '20px', fontSize: '11px' }}>
              <span>
                {'Questo sito è protetto da reCAPTCHA: la '}
                <a href="https://policies.google.com/privacy" style={{ textDecoration: 'underline' }} target="_blank">
                  privacy policy
                </a>
                {' e i '}
                <a href="https://policies.google.com/terms" style={{ textDecoration: 'underline' }} target="_blank">
                  termini di servizio
                </a>
                {' di Google si applicano.'}
              </span>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  const DEFAULT_TOCWA = true;
  useEffect(() => {
    updateAnswer(question.step + 7, DEFAULT_TOCWA, question.var_name);
  }, []);

  return (
    <div className={cn(generic.containerSlide, 'popup-container', 'popup-preventivatore', 'page-form')}>
      <div className={cn(generic.containerSlideLatest, 'popup-content')}>
        {!status ? (
          <div className={cn(generic.rowcontainerLead, 'popup-rowcontainer')}>
            <div className={cn(generic.rightColLead, 'popup-rightCol')}>
              <div className={cn(styles.buttons)}>
                <div style={{ minHeight: 'inherit' }} className={cn(generic.question_label, generic.question_label_align)}>
                  {question.question_label}
                  <span className={cn(generic.question_sublabel)}>{question.question_subtitle}</span>
                </div>
                <div style={{ textAlign: 'left' }} className={cn(styles.answersContainer, 'lead-form-container')}>
                  <CustomTextInput
                    label={'Nome'}
                    type="text"
                    contentContainerStyle={overrideMinHeight}
                    className="compact_custom_input"
                    value={answers[question.step] ? answers[question.step] : ''}
                    onChange={e => {
                      updateAnswer(question.step, e.target.value, question.var_name);
                    }}
                    // errorMessage={errors?.name?.message}
                    ref={null}
                  />
                  <CustomTextInput
                    label={'Cognome'}
                    type="text"
                    contentContainerStyle={overrideMinHeight}
                    className="compact_custom_input"
                    value={answers[question.step + 1] ? answers[question.step + 1] : ''}
                    onChange={e => {
                      updateAnswer(question.step + 1, e.target.value, question.var_name);
                    }}
                    // errorMessage={errors?.name?.message}
                    ref={null}
                  />
                  <CustomTextInput
                    label={'Email'}
                    type="text"
                    contentContainerStyle={overrideMinHeight}
                    className="compact_custom_input"
                    value={answers[question.step + 2] ? answers[question.step + 2] : ''}
                    onChange={e => {
                      updateAnswer(question.step + 2, e.target.value, question.var_name);
                    }}
                    // errorMessage={errors?.name?.message}
                    ref={null}
                  />
                  <CustomTelephoneInput
                    label={'Telefono'}
                    type="text"
                    value={answers[question.step + 3] ? answers[question.step + 3] : ''}
                    onChange={e => {
                      // console.log('eheieheiehie');
                      updateAnswer(question.step + 3, (e?.target?.value || '').replace(/\D+/g, ''), question.var_name);
                    }}
                    onChangePrefix={(prefix, prefixDetails) => {
                      updateAnswer(question.step + 4, prefix, question.var_name);
                    }}
                    options={[
                      { value: 'permanent_contract', label: t('preventivatore.permanent_contract') },
                      { value: 'self_employed', label: t('preventivatore.self_employed') },
                      { value: 'retired', label: t('preventivatore.retired') },
                      { value: 'other', label: t('preventivatore.other') },
                    ]}
                    ref={null}
                  />
                  <div className={`input-checkbox`} style={{ maxWidth: '100%', width: '100%' }}>
                    <div className={`input-checkbox`} style={{ maxWidth: '800px', width: '100%', marginTop: '10px' }}>
                      <label htmlFor="toc">
                        <span dangerouslySetInnerHTML={{ __html: t('preventivatore.accept_privacy') }}></span>
                        <input
                          type="checkbox"
                          id="toc"
                          checked={answers[question.step + 6] ? answers[question.step + 6] : false}
                          value={answers[question.step + 6] ? answers[question.step + 6] : false}
                          onChange={e => {
                            setError(null);
                            updateAnswer(question.step + 6, !answers[question.step + 6], question.var_name);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className={`input-checkbox`} style={{ maxWidth: '100%', width: '100%', display: 'none' }}>
                    <div className={`input-checkbox`} style={{ maxWidth: '800px', width: '100%', marginTop: '10px' }}>
                      <label htmlFor="tocwa">
                        <span dangerouslySetInnerHTML={{ __html: t('preventivatore.accept_privacy_wa') }}></span>
                        <input
                          type="checkbox"
                          id="tocwa"
                          // Vedi DEFAULT_TOCWA e useEffect associato
                          checked={typeof answers[question.step + 7] !== 'undefined' ? answers[question.step + 7] : DEFAULT_TOCWA}
                          value={typeof answers[question.step + 7] !== 'undefined' ? answers[question.step + 7] : DEFAULT_TOCWA}
                          onChange={e => {
                            updateAnswer(question.step + 7, !answers[question.step + 7], question.var_name);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  {/*<div style={{ marginBottom: '0' }}>
    <ReCAPTCHA sitekey={config.captchaKey} ref={captchaRef} size="invisible" />
  </div>*/}
                  {error ? (
                    <div style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }}>
                      <span>{error}</span>
                    </div>
                  ) : null}
                </div>
                <div className={cn(generic.stepButton)}>
                  <button
                    onClick={() => {
                      if (check()) {
                        // TODO mi metto qui in mezzo per fare il controllo del telefono
                        phoneControl();
                        // next(question.last_question);
                        window.scrollTo(0, 0);
                      }
                    }}
                    className={cn(generic.desktop, generic.nextButton, generic.lastButton, generic.leadSubmit, generic.greenButton)}
                    rel="nofollow">
                    {'INVIA RICHIESTA'}
                  </button>
                  <button
                    onClick={() => {
                      if (check()) {
                        phoneControl();
                        // next(question.last_question);
                        window.scrollTo(0, 0);
                      }
                    }}
                    className={cn(generic.mobile, generic.nextButton, generic.lastButton, generic.leadSubmit, generic.greenButton)}
                    rel="nofollow">
                    {'INVIA RICHIESTA >>'}
                  </button>
                </div>
                {/* <div style={{ textAlign: 'center', marginBottom: '20px', fontSize: '11px' }}>
                  <span>
                    {'Questo sito è protetto da reCAPTCHA: la '}
                    <a href="https://policies.google.com/privacy" style={{ textDecoration: 'underline' }} target="_blank">
                      privacy policy
                    </a>
                    {' e i '}
                    <a href="https://policies.google.com/terms" style={{ textDecoration: 'underline' }} target="_blank">
                      termini di servizio
                    </a>
                    {' di Google si applicano.'}
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        ) : null}
        {status === 'requesting' ? requestingStatus() : null}
        {status === 'phoneError' ? phoneErrorStatus() : null}
      </div>
    </div>
  );
};

export default Question;
