import axios from 'axios';
import config from '../../config/config.js';
import debounce from '../../utilities/debounce.js';

const { baseUrl } = config;

async function updateUserRequestUserInfo(userRequestId, userInfo, answerRow, product) {
  const endpoint = `${baseUrl}/v1/requests/user/updateUserRequestUserInfo`;
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': `tassomutuo.it`,
      'Access-Control-Allow-Credentials': true,
    },
  };
  const data = { userRequestId, ...Object.fromEntries(Object.entries(userInfo).filter(([k, v]) => !!v)) };
  if (answerRow) {
    data.answerRow = answerRow;
  }
  if (product) {
    data.product = product;
  }
  try {
    await axios.post(endpoint, data, options);
  } catch (err) {
    // console.log(`updateUserRequestUserInfo failed: ${err.message}`)
  }
}

const debounced = debounce(updateUserRequestUserInfo, 500);

export default debounced;
