import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/buttons.module.css';
import generic from '../styles/question.module.css';

const Question = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  function check() {
    if (question.required && !answers[question.step]) {
      setError('Seleziona una risposta');
      return false;
    }
    setError(null);
    return true;
  }

  return (
    <div className={cn(styles.buttons)}>
      <div className={cn(generic.question_label)}>{question.question_label}</div>
      <div className={cn(styles.answersErrorContainer)}>
        <div className={cn(styles.answersContainer)}>
          {question.question_answers.map((ansVal, index) => {
            const selected = answers[question.step] === ansVal.value ? styles.selected : null;
            return (
              <button
                onClick={() => {
                  updateAnswer(question.step, ansVal.value, question.var_name);
                  setError(null);
                  next(question.last_question, question.step, ansVal.value);
                }}
                className={cn(styles.button, selected)}
                key={'ans' + question.step + '-' + index}>
                {ansVal.img ? (
                  <img style={{ marginRight: '8px', width: '25px' }} alt={ansVal.label} title={ansVal.label} src={`/${ansVal.img}`} />
                ) : null}
                {ansVal.label}
              </button>
            );
          })}
        </div>
        {error ? <div className={cn(styles.errorMessage)}>{error}</div> : null}
      </div>
      <div className={cn(generic.stepButton)}>
        {question.step > 0 ? (
          <button
            onClick={() => {
              setError(null);
              prev();
            }}
            className={cn(generic.prevButton)}
            rel="nofollow">
            {'<<'}
          </button>
        ) : null}
        <button
          onClick={() => {
            if (check()) {
              setError(null);
              next(question.last_question, question.step, answers[question.step]);
            }
          }}
          className={cn(generic.nextButton)}
          rel="nofollow">
          {'AVANTI'}
          <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
        </button>
      </div>

      <div className={cn(generic.suggestion)}>{question.suggestion}</div>
    </div>
  );
};

export default Question;
