import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from '../styles/select.module.css';
import introstyle from '../styles/intro.module.css';
import generic from '../styles/question.module.css';


const Intro = ({ question, next, prev, answers, updateAnswer }) => {
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  return (
    <div className={cn(styles.select)}>
      <div className={cn(generic.question_label)}>
        {question.question_label_1}
        <span className={cn(introstyle.valInput)}>{`200000 €`}</span>
        {question.question_label_2}
        <span className={cn(introstyle.valInput)}>{`tasso fisso`}</span>
        {question.question_label_3}
        <span className={cn(introstyle.valInput)}>{`bnl`}</span>
      </div>

      <div className={cn(generic.suggestion)}>{question.introtext}</div>

      <div className={cn(generic.stepButton)}>
        {question.step > 0 ? (
          <button
            onClick={() => {
              setError(null);
              prev();
            }}
            className={cn(generic.prevButton)}
            rel="nofollow">
            {'<<'}
          </button>
        ) : null}
        <button
          onClick={() => {
            next(question.last_question, question.step);
          }}
          className={cn(generic.nextButton)}
          rel="nofollow">
          {'AVANTI'}
          <span className={cn(generic.nextButtonCross)}>{' >>'}</span>
        </button>
      </div>

      <div className={cn(generic.suggestion)}>{question.suggestion}</div>
    </div>
  );
};

export default Intro;
