import React from 'react';
import ReactTelInput from 'react-telephone-input';
import cn from 'classnames';

import styles from './styles/CustomTelephoneInput.module.css';
import { useTranslation } from 'react-i18next';

const CustomTelephoneInput = ({
  label,
  type = 'text',
  value,
  onChange,
  errorMessage = null,
  showCurrencyIcon = false,
  showPercentageIcon = false,
  styleInput = {},
  onChangePrefix,
  placeholder,
  initialValuePrefix = '+39',
  ...other
}) => {
  const { t } = useTranslation();
  
  return (
    <div className={cn(styles.inputContainer, styles.phoneInput, errorMessage && styles.error)}>
      <label htmlFor="phone">{label}</label>
      <div className={cn(styles.prefixContainer, 'select-prefix-container')}>

        <link rel="stylesheet" type="text/css" href="/css/telephoneFlags.css" />
        <ReactTelInput
          defaultCountry="it"
          initialValue={initialValuePrefix}
          onChange={onChangePrefix}
          autoFormat
          inputProps={{ readOnly: true, autoFocus: false }}
          onBlur={() => {}}
          flagsImagePath={`/flags.png`}
          autoComplete="off"
          listStyle={{
            zIndex: 99999999,
            backgroundColor: 'white',
            width: '100px',
            position: 'absolute',
          }}
        />
      </div>
      <input type="text" id="phone" placeholder={placeholder} value={value} onChange={onChange} />
      {errorMessage ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : (
        <span className={styles.errorMessage} style={{ color: 'transparent' }}>
          '
        </span>
      )}
    </div>
  );
};

export default CustomTelephoneInput;
