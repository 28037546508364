import { useEffect, useState } from 'react';

export default function useIntersection(element, rootMargin) {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const current = element?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin },
    );
    if (current && observer) {
      observer.observe(current);
    }

    return () => current && observer.unobserve(current);
  }, []);

  return isVisible;
}
