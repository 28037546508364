import React from 'react';
import cn from 'classnames';
import styles from './styles/CustomSelectInput.module.css';
import Select from 'react-select';

const CustomSelectInput = ({ label, value, onChange, options, className="", errorMessage = null, id = 'idSelectInput', contentContainerStyle = {}, ...other }) => {
  return (
    <div className={cn(styles.inputContainer, errorMessage && styles.error)} style={contentContainerStyle}>
      <label htmlFor={id}>{label}</label>
      <Select
        className={cn(styles.select)}
        styles={{
          control: styles => ({
            ...styles,
            borderRadius: '0px',
            minWidth: '260px',
            borderColor: '#707070',
            minHeight:'55px'
          }),

          input: styles => ({ ...styles,
            fontSize: '23px',
            padding: '10px 20px',
            color: 'rgb(117, 117, 117)'
          }),
          singleValue: styles => ({ ...styles,
            fontSize: '23px',
            padding: '10px 20px',
            color: 'rgb(117, 117, 117)'
          }),
          option: styles => ({ ...styles }),
        }}
        onChange={onChange}
        options={options}
        isSearchable={false}
        value={{ value, label: options?.find(o => o.value === value)?.label || value }}
      />
      {errorMessage ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : (
        <span className={styles.errorMessage} style={{ color: 'transparent' }}>
          '
        </span>
      )}
    </div>
  );
};

export default CustomSelectInput;
